@import url(https://fonts.googleapis.com/css2?family=Train+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Train+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bm-burger-button {
    position: fixed;
    width: 2.4rem;
    height: 1.7rem;
    left: 3%;
    top: 3%;
    z-index: 10 !important;
}

.bm-menu {
    width: 100%;
    overflow: hidden !important;
}

.bm-menu-wrap {
    z-index: 200 !important;
}

.bm-overlay {
    z-index: 100 !important;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-cross {
    background-color: whitesmoke;
}

.bm-cross-button{
    width: 1.5rem !important;
    height: 1.5rem !important;
    top: 1.75vh !important;
    right: 1.75vh !important;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}


.navbar-item {
    color: floralwhite;
    font-size: xx-large;
    font-family: 'Poppins' !important;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
}

.navbar-item:focus {
    outline: none;
}

#home {
    margin-top: 1vh
}

.home {
    height: 100vh;
    display: -webkit-flex;
    display: flex;
}

.home-left-container {
    background: black;
    height: 100vh;
    width: 50%;
    color: white;
}

.home-right-container {
    height: 100vh;
    width: 50%;
}

.home-name {
    font-size: 6.5rem;
    text-align: right;
    padding-top: 35%;
    padding-right: 4vw;
    font-family: 'Saira Semi Condensed';
}


.lastname {
    font-size: 6.5rem;
    padding-top: 35%;
    padding-left: 4vw;
    padding-bottom: 5vh;
    font-family: 'Saira Semi Condensed';

}

.rotate-text {
    padding-left: 11vw;
    font-size: 1.75rem;
    font-weight: 650;
}

.react-rotating-text-cursor {
    -webkit-animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
            animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@-webkit-keyframes blinking-cursor {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }

@keyframes blinking-cursor {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
.about {
    background-color: #F8F5F2;
    display: grid;
    grid-template-columns: 40vw auto;
    grid-template-rows: 5vh 5vh 120vh;
}

.about-heading {
    position: relative;
    left: 50vw;
    padding-top: 4rem;
    -webkit-transform: translate(-25%, 100%);
            transform: translate(-25%, 100%);
    font-size: 5rem;
    font-weight: lighter;
    color: #2C4A52;
}

.about-profile {
    margin-top: auto;
    margin-bottom: auto;
    grid-column: 1;
    grid-row: 3;
}

.profile-container {
    width: -webkit-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-top: 50%;
    margin-bottom: 50%;
}

.about-links {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.about-links a {
    font-size: 2.2rem;
    color: #2C4A52;
}

.profile-container img{
    width: 20rem;
    border-radius: 50%;
    margin-bottom: 4rem;
}

.about-text {
    grid-column: 2;
    grid-row: 3;
    color: #2C4A52;
    width: 35vw;
    margin: auto;
    padding-top: 5%;
    font-size: x-large;
}

.space {
    height: 1rem;
}

.transition-container {
    display: -webkit-flex;
    display: flex;
    height: 30vh;
    grid-column: 1/3;
    grid-row: 1;
}

.transition-to-about {
    grid-column: 1/3;
    grid-row: 1;
    background: linear-gradient(
        white 0%,
        #F8F5F2 100%);
}
.timeline {
    background-color: #E2E8E4;
    padding-bottom: 5%;
}

.timeline-header {
    font-size: 5.5rem;
    text-align: center;
    padding: 5rem;
    color: #16253D;
    font-weight: lighter;
}

.timeline ul {
    padding-left: 2rem;
}

.vertical-timeline-element-content p {
    padding-bottom: .5rem;
}

.vertical-timeline-element-date {
    width: -webkit-fit-content !important;
    width: fit-content !important;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1vw;
    -webkit-backdrop-filter: 1vw;
            backdrop-filter: 1vw;
    box-shadow: 0 .5vh .5vw rgba(0, 0, 0, 0.1);
    border: .2vh solid rgba(255, 255, 255, 0.5);
    border-right: .1vh solid rgba(255, 255, 255, .2);
    border-bottom: .1vh solid rgba(255, 255, 255, .2);
    color: #2A3132;
    padding: 1vh 1vw !important;
}

.read-more-link {
    text-align: center;
}

.read-more-link h2:hover {
    color: #763626;
}

.extra-content {
    color: #727077;
    text-align: left;
    font-size: large;
    padding-top: 5%;
    width: 95%;
}

.extra-content li {
    padding-bottom: 5%;
}

.certificate-image {
    display: block;
    padding-top: .5rem;
    width: 20vh;
    margin-left: auto;
    margin-right: auto;
}

.timeline-text {
    text-align: center;
    margin: .3rem;
}

.vertical-timeline-element h2 {
    color: #2A3132;
}

.vertical-timeline-element h3, h4 {
    color: #727077;
}

.vertical-timeline-element-content {
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1vw;
    -webkit-backdrop-filter: 1vw;
            backdrop-filter: 1vw;
    box-shadow: 0 .5vh .5vw rgba(0, 0, 0, 0.1);
    border: .2vh solid rgba(255, 255, 255, 0.5);
    border-right: .1vh solid rgba(255, 255, 255, .2);
    border-bottom: .1vh solid rgba(255, 255, 255, .2);
}

.transition-to-timeline {
    height: 5vh;
    background: linear-gradient(
        #F8F5F2 0%,
        #E2E8E4 100%);
}

.carousel-root {
    padding-top: 1.25rem;
}

.carousel-slider {
    margin-left: auto !important;
    margin-right: auto !important;
}

.legend {
    font-size: large !important;
    margin-bottom: 160% !important;
}

.control-arrow {
    padding: 1rem !important;
}
.skills {
    min-height: 130vh;
    background-color: #626D71;
    color: #CDCDC0;
}

.skills-heading {
    position: absolute;
    padding-top: 5rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 5rem;
    font-weight: lighter;
}

.skills-cards-container {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    top: 50vh;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
}

.skills-card {
    height: 27rem;
    width: 22rem;
    background-color: #373a47;
    border-radius: 1rem;
    box-shadow: -1rem .5rem 1rem rgba(0, 0, 0, .75);
    transition: 0.4s ease-out;
    position: relative;
    left: 0;
}

.skills-card h3 {
    font-size: xx-large;
    padding-top: 4vh;
    text-align: center;
    font-weight: lighter;
}

.skills-card:not(:first-child) {
    margin-left: -4rem;
}

.skills-card:hover {
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem);
    transition: 0.4s ease-out;
}

.skills-card:hover ~ .skills-card {
    position: relative;
    left: 4.5rem;
    transition: 0.4s ease-out;
}

.skills-bar {
    position: absolute;
    top: 30%;
    left: 10%;
    height: .4rem;
    width: 80%;
}

.skills-bar .empty {
    background-color: #2E3033;
    width: 100%;
    height: 100%;
}

.skills-bar .filled {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 0;
    height: 100%;
    background: rgb(0, 154, 217);
    background: linear-gradient(
        90deg,
        #373a47 0%,
        #536976 50%,
        #BBD2C5 100%);
    transition: 0.6s ease-out;
}

.skills-card:hover .filled.backend{
    width: 75%;
}

.skills-card:hover .filled.frontend{
    width: 60%;
}

.skills-card:hover .filled.database{
    width: 40%;
}

.skills-detail {
    width: 70%;
    margin-top: 30%;
    margin-left: auto;
    margin-right: auto;
}

.skills-detail li {
    padding-bottom: 1rem;
    font-size: larger;
}

.transition-to-skills {
    height: 3vh;
    background: linear-gradient(
        #E2E8E4 0%,
        #626D71 100%);
}
.contact {
    min-height: 110vh;
    background: #2A3132;
    color: #CDCDC0;
}

.contact-transition {
    height: 3vh;
    background: linear-gradient(
        #626D71 0%,
        #2A3132 100%);
}

.contact-heading {
    padding: 5rem;
    margin-left: 5rem;
    font-size: 5rem;
    font-weight: lighter;
    text-align: center;
}

.contact-container {
    display: -webkit-flex;
    display: flex;
    height: 60vh;
}

.contact-info {
    position: relative;
    display: -webkit-flex;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    left: 35vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    text-align: center;
    font-size: larger;
    width: 18vw;
    height: 45vh;
    background: #27383f;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.9);
    z-index: 1;
    border-radius: 1rem;
}

.contact-info h2 {
    font-weight: lighter;
    margin: -1.5rem;
}

.contact-info ul {
    list-style: none;
}

li svg {
    font-size: larger;
    width: 2.5rem
}

.contact-form {
    position: relative;
    background-color: aliceblue;
    left: 18vh;
    width: 50%;
    height: 100%;
    box-shadow: 1rem 1rem 1rem rgba(255, 255, 255, .3);
    border-radius: 1rem;
}

.form-heading {
    font-size: 2.5rem;
    font-weight: lighter;
    margin-left: 21%;
    padding: 4vh 0 3vh 10vw;
    color: #262F34;
}

.contact-form-box {
    position: relative;
    top: 5%;
    padding-left: 25%;
    padding-right: 15%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.input-box {
    margin-bottom: 2.5rem;
    font-size: large;
}

.input-box.w50 {
    width: 43%;
}

.input-box.w100 {
    margin-top: 2%;
    width: 100%;
}

.input-box input, textarea {
    background-color: aliceblue;
    width: 100%;
    font-size: large;
    border: none;
    border-bottom: .25vh solid grey;
    outline: none;
    resize: none;
}

.input-box textarea {
    font-size: larger;
    min-height: 10vh;
}

.input-box span {
    position: absolute;
    margin-left: -25.5%;
    margin-top: -.25vh;
    transition: 0.3s;
    pointer-events: none;
}

.textbox-text {
    margin-left: -26rem !important;
}

.input-box input:focus ~ span,
.input-box textarea:focus ~ span,
.input-box input:valid ~ span,
.input-box textarea:valid ~ span {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    font-size: smaller;
    color: #E99787;
}

.btn {
	position: relative;
	padding: 1.5rem 2rem;
    margin-left: 26%;
    overflow: hidden;
	z-index: 1;
    border: none;
	background: #324851;
    border-radius: 1rem;
    box-shadow: .7rem .7rem .75rem rgba(0, 0, 0, 0.9);
}

.btn h2 {
    color: #CDCDC0;
    font-weight: lighter;
}

.btn:after {
	content: '';
    position: absolute;
	z-index: -1;
	transition: all .5s;
	width: 100%;
	height: 0;
	top: 50%;
	left: 50%;
	background: aliceblue;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.btn:hover:after {
    height: 140%;
	opacity: 1;
}

.btn:active:after {
	height: 400%;
	opacity: 1;
}

* {
  margin: 0;
  padding: 0;
  cursor: none;
}

body {
  font-family: 'Poppins';
}
