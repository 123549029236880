* {
  margin: 0;
  padding: 0;
  cursor: none;
}

body {
  font-family: 'Poppins';
}


@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');