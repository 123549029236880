.contact {
    min-height: 110vh;
    background: #2A3132;
    color: #CDCDC0;
}

.contact-transition {
    height: 3vh;
    background: linear-gradient(
        #626D71 0%,
        #2A3132 100%);
}

.contact-heading {
    padding: 5rem;
    margin-left: 5rem;
    font-size: 5rem;
    font-weight: lighter;
    text-align: center;
}

.contact-container {
    display: flex;
    height: 60vh;
}

.contact-info {
    position: relative;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    left: 35vh;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    font-size: larger;
    width: 18vw;
    height: 45vh;
    background: #27383f;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.9);
    z-index: 1;
    border-radius: 1rem;
}

.contact-info h2 {
    font-weight: lighter;
    margin: -1.5rem;
}

.contact-info ul {
    list-style: none;
}

li svg {
    font-size: larger;
    width: 2.5rem
}

.contact-form {
    position: relative;
    background-color: aliceblue;
    left: 18vh;
    width: 50%;
    height: 100%;
    box-shadow: 1rem 1rem 1rem rgba(255, 255, 255, .3);
    border-radius: 1rem;
}

.form-heading {
    font-size: 2.5rem;
    font-weight: lighter;
    margin-left: 21%;
    padding: 4vh 0 3vh 10vw;
    color: #262F34;
}

.contact-form-box {
    position: relative;
    top: 5%;
    padding-left: 25%;
    padding-right: 15%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.input-box {
    margin-bottom: 2.5rem;
    font-size: large;
}

.input-box.w50 {
    width: 43%;
}

.input-box.w100 {
    margin-top: 2%;
    width: 100%;
}

.input-box input, textarea {
    background-color: aliceblue;
    width: 100%;
    font-size: large;
    border: none;
    border-bottom: .25vh solid grey;
    outline: none;
    resize: none;
}

.input-box textarea {
    font-size: larger;
    min-height: 10vh;
}

.input-box span {
    position: absolute;
    margin-left: -25.5%;
    margin-top: -.25vh;
    transition: 0.3s;
    pointer-events: none;
}

.textbox-text {
    margin-left: -26rem !important;
}

.input-box input:focus ~ span,
.input-box textarea:focus ~ span,
.input-box input:valid ~ span,
.input-box textarea:valid ~ span {
    transform: translateY(-100%);
    font-size: smaller;
    color: #E99787;
}

.btn {
	position: relative;
	padding: 1.5rem 2rem;
    margin-left: 26%;
    overflow: hidden;
	z-index: 1;
    border: none;
	background: #324851;
    border-radius: 1rem;
    box-shadow: .7rem .7rem .75rem rgba(0, 0, 0, 0.9);
}

.btn h2 {
    color: #CDCDC0;
    font-weight: lighter;
}

.btn:after {
	content: '';
    position: absolute;
	z-index: -1;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all .5s;
	width: 100%;
	height: 0;
	top: 50%;
	left: 50%;
	background: aliceblue;
	opacity: 0;
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	-moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	-ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.btn:hover:after {
    height: 140%;
	opacity: 1;
}

.btn:active:after {
	height: 400%;
	opacity: 1;
}
