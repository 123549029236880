.timeline {
    background-color: #E2E8E4;
    padding-bottom: 5%;
}

.timeline-header {
    font-size: 5.5rem;
    text-align: center;
    padding: 5rem;
    color: #16253D;
    font-weight: lighter;
}

.timeline ul {
    padding-left: 2rem;
}

.vertical-timeline-element-content p {
    padding-bottom: .5rem;
}

.vertical-timeline-element-date {
    width: fit-content !important;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1vw;
    backdrop-filter: 1vw;
    box-shadow: 0 .5vh .5vw rgba(0, 0, 0, 0.1);
    border: .2vh solid rgba(255, 255, 255, 0.5);
    border-right: .1vh solid rgba(255, 255, 255, .2);
    border-bottom: .1vh solid rgba(255, 255, 255, .2);
    color: #2A3132;
    padding: 1vh 1vw !important;
}

.read-more-link {
    text-align: center;
}

.read-more-link h2:hover {
    color: #763626;
}

.extra-content {
    color: #727077;
    text-align: left;
    font-size: large;
    padding-top: 5%;
    width: 95%;
}

.extra-content li {
    padding-bottom: 5%;
}

.certificate-image {
    display: block;
    padding-top: .5rem;
    width: 20vh;
    margin-left: auto;
    margin-right: auto;
}

.timeline-text {
    text-align: center;
    margin: .3rem;
}

.vertical-timeline-element h2 {
    color: #2A3132;
}

.vertical-timeline-element h3, h4 {
    color: #727077;
}

.vertical-timeline-element-content {
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1vw;
    backdrop-filter: 1vw;
    box-shadow: 0 .5vh .5vw rgba(0, 0, 0, 0.1);
    border: .2vh solid rgba(255, 255, 255, 0.5);
    border-right: .1vh solid rgba(255, 255, 255, .2);
    border-bottom: .1vh solid rgba(255, 255, 255, .2);
}

.transition-to-timeline {
    height: 5vh;
    background: linear-gradient(
        #F8F5F2 0%,
        #E2E8E4 100%);
}

.carousel-root {
    padding-top: 1.25rem;
}

.carousel-slider {
    margin-left: auto !important;
    margin-right: auto !important;
}

.legend {
    font-size: large !important;
    margin-bottom: 160% !important;
}

.control-arrow {
    padding: 1rem !important;
}


@import url('https://fonts.googleapis.com/css2?family=Train+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');