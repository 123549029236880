.skills {
    min-height: 130vh;
    background-color: #626D71;
    color: #CDCDC0;
}

.skills-heading {
    position: absolute;
    padding-top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 5rem;
    font-weight: lighter;
}

.skills-cards-container {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    top: 50vh;
    transform: translateY(-20%);
}

.skills-card {
    height: 27rem;
    width: 22rem;
    background-color: #373a47;
    border-radius: 1rem;
    box-shadow: -1rem .5rem 1rem rgba(0, 0, 0, .75);
    transition: 0.4s ease-out;
    position: relative;
    left: 0;
}

.skills-card h3 {
    font-size: xx-large;
    padding-top: 4vh;
    text-align: center;
    font-weight: lighter;
}

.skills-card:not(:first-child) {
    margin-left: -4rem;
}

.skills-card:hover {
    transform: translateY(-2rem);
    transition: 0.4s ease-out;
}

.skills-card:hover ~ .skills-card {
    position: relative;
    left: 4.5rem;
    transition: 0.4s ease-out;
}

.skills-bar {
    position: absolute;
    top: 30%;
    left: 10%;
    height: .4rem;
    width: 80%;
}

.skills-bar .empty {
    background-color: #2E3033;
    width: 100%;
    height: 100%;
}

.skills-bar .filled {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 0;
    height: 100%;
    background: rgb(0, 154, 217);
    background: linear-gradient(
        90deg,
        #373a47 0%,
        #536976 50%,
        #BBD2C5 100%);
    transition: 0.6s ease-out;
}

.skills-card:hover .filled.backend{
    width: 75%;
}

.skills-card:hover .filled.frontend{
    width: 60%;
}

.skills-card:hover .filled.database{
    width: 40%;
}

.skills-detail {
    width: 70%;
    margin-top: 30%;
    margin-left: auto;
    margin-right: auto;
}

.skills-detail li {
    padding-bottom: 1rem;
    font-size: larger;
}

.transition-to-skills {
    height: 3vh;
    background: linear-gradient(
        #E2E8E4 0%,
        #626D71 100%);
}