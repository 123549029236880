.about {
    background-color: #F8F5F2;
    display: grid;
    grid-template-columns: 40vw auto;
    grid-template-rows: 5vh 5vh 120vh;
}

.about-heading {
    position: relative;
    left: 50vw;
    padding-top: 4rem;
    transform: translate(-25%, 100%);
    font-size: 5rem;
    font-weight: lighter;
    color: #2C4A52;
}

.about-profile {
    margin-top: auto;
    margin-bottom: auto;
    grid-column: 1;
    grid-row: 3;
}

.profile-container {
    width: fit-content;
    margin-left: auto;
    margin-top: 50%;
    margin-bottom: 50%;
}

.about-links {
    display: flex;
    justify-content: space-between;
}

.about-links a {
    font-size: 2.2rem;
    color: #2C4A52;
}

.profile-container img{
    width: 20rem;
    border-radius: 50%;
    margin-bottom: 4rem;
}

.about-text {
    grid-column: 2;
    grid-row: 3;
    color: #2C4A52;
    width: 35vw;
    margin: auto;
    padding-top: 5%;
    font-size: x-large;
}

.space {
    height: 1rem;
}

.transition-container {
    display: flex;
    height: 30vh;
    grid-column: 1/3;
    grid-row: 1;
}

.transition-to-about {
    grid-column: 1/3;
    grid-row: 1;
    background: linear-gradient(
        white 0%,
        #F8F5F2 100%);
}